<template>
  <b-card title="Filters" class="mb-0">
    <b-form @submit.prevent>
      <b-row>
        <!-- Period -->
        <b-col cols="12" md="2">
          <b-form-group
            :label="$t('apps.reportLedger.singular.period')"
            label-for="period"
          >
            <v-select
              class="select-size-sm"
              v-model="form.period"
              :options="LOV.period"
              :reduce="field => field.value"
              @input="handlePeriod"
            />
          </b-form-group>
        </b-col>

        <!-- Date Start -->
        <b-col cols="12" md="2">
          <b-form-group
            :label="$t('apps.reportLedger.singular.dateStart')"
            label-for="date-start"
          >
            <flat-pickr
              id="date-start"
              :config="dateFormat"
              v-model="form.dateStart"
              class="form-control form-control-sm"
            />
          </b-form-group>
        </b-col>

        <!-- Date End -->
        <b-col cols="12" md="2">
          <b-form-group
            :label="$t('apps.reportLedger.singular.dateEnd')"
            label-for="date-start"
          >
            <flat-pickr
              id="date-end"
              :config="dateFormat"
              v-model="form.dateEnd"
              class="form-control form-control-sm"
            />
          </b-form-group>
        </b-col>

        <!-- Office Id -->
        <b-col cols="12" md="2">
          <b-form-group
            :label="$t('globalSingular.office')"
            label-for="office"
          >
            <v-select
              class="select-size-sm"
              v-model="form.officeId"
              :options="LOV.offices"
              :reduce="field => field.id"
              label="label"
              @option:selected="handleOffice"
            />
          </b-form-group>
        </b-col>

        <!-- Region -->
        <b-col cols="12" md="2">
          <b-form-group
            :label="$t('globalSingular.region')"
            label-for="office"
          >
            <v-select
              class="select-size-sm"
              v-model="form.regions"
              :options="LOV.regions"
              :reduce="field => field.name"
              label="name"
            />
          </b-form-group>
        </b-col>

        <!-- Tags -->
        <b-col cols="12" md="2">
          <b-form-group
            :label="$t('globalSingular.tags')"
            label-for="tags"
          >
            <v-select
              v-model="form.tags"
              id="tags"
              label="name"
              class="select-size-sm"
              :options="LOV.tags"
              :reduce="field => field.id"
            />
          </b-form-group>
        </b-col>

        <!-- Button Filter -->
        <b-col cols="12" md="2">
          <b-button block variant="primary" @click="generateEndpointAPI" class="mt-2 pd-1">
            {{ $t('globalActions.find') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BButton
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { onMounted, ref } from '@vue/composition-api'
import useLocalization from '@/comp-functions/utils/useLocalization'
import useHttp from '@/comp-functions/useHttp'

import { format, getMonth, startOfMonth, endOfMonth, subMonths, addMonths, subYears, parse } from 'date-fns'

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    baseReportUrl: {
      type: String,
      required: true
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButton,
    flatPickr,
    vSelect
  },
  setup (props, { emit }) {
    const { $t } = useLocalization()
    const { $get } = useHttp()
    const selectedOffice = ref({
      id: null, 
      name: ''
    })
    const form = ref({
      tags: '',
      period: 'thisMonth',
      officeId: null,
      dateStart: '',
      dateEnd: '',
      regions: null
    })

    const generatedUrl = (dateStart = form.value.dateStart, dateEnd = form.value.dateEnd, officeId = form.value.officeId, tags = form.value.tags,regions = form.value.regions) => {
      return `${props.baseReportUrl}?dateStart=${dateStart}&dateEnd=${dateEnd}&officeId=${officeId}&tags={${tags}}&regions=${regions}`
    }

    const periodReportText = ref()
    const dateFormat = ref({ dateFormat: 'd/m/Y' })

    const LOV = ref({
      period: [
        { value: 'thisMonth', label: 'This Month' },
        { value: 'previousMonth', label: 'Previous Month' },
        { value: 'thisYear', label: 'This Year' },
        { value: 'previousYear', label: 'Previous Year' }
      ],
      tags: [],
      offices: [],
      regions:[]
    })

    const handlePeriod = () => {
      const formatString = 'dd/MM/yyyy'
      const thisMonth = new Date()
      const whatMonth = getMonth(thisMonth) + 1
      const previousMonth = subMonths(thisMonth, 1)
      const firstMonthOfYear = subMonths(thisMonth, whatMonth - 1)
      const lastMonthOfYear = addMonths(thisMonth, (whatMonth - 12) * -1)
      const firstMonthOfPreviousYear = subYears(firstMonthOfYear, 1)
      const lastMonthOfPreviousYear = subYears(lastMonthOfYear, 1)

      if (form.value.period === 'thisMonth') {
        form.value.dateStart = format(startOfMonth(thisMonth), formatString)
        form.value.dateEnd = format(endOfMonth(thisMonth), formatString)
      } else if (form.value.period === 'previousMonth') {
        form.value.dateStart = format(startOfMonth(previousMonth), formatString)
        form.value.dateEnd = format(endOfMonth(previousMonth), formatString)
      } else if (form.value.period === 'thisYear') {
        form.value.dateStart = format(startOfMonth(firstMonthOfYear), formatString)
        form.value.dateEnd = format(endOfMonth(lastMonthOfYear), formatString)
      } else {
        form.value.dateStart = format(startOfMonth(firstMonthOfPreviousYear), formatString)
        form.value.dateEnd = format(endOfMonth(lastMonthOfPreviousYear), formatString)
      }
    }

    const handleOffice = (val) => { selectedOffice.value = {id: val.id, name: val.name} }

    const getOffice = async () => {
      const { data } = await $get({ url: 'master/contact?type={CTAG}'})
      LOV.value.offices = data
    }

    const getRegions = async () => {
      const { data } = await $get({ url: 'master/regions'})
      LOV.value.regions = data
    }

    const getTags = async () => {
      const { data } =  await $get({ url: 'value/tag'})
      LOV.value.tags = data
    }

    const updatePeriodText = () => {
      const dateStart = parse(form.value.dateStart, 'dd/MM/yyyy', new Date())
      const dateEnd = parse(form.value.dateEnd, 'dd/MM/yyyy', new Date())
      const formatStartDate = format(dateStart, 'd MMMM yyyy')
      const formatEndDate = format(dateEnd, 'd MMMM yyyy')
      const text = `${formatStartDate} to ${formatEndDate}`
      periodReportText.value = text
      return text
    }

    const updateOfficeText = () => {
      return selectedOffice.value.id ? `${$t('globalSingular.office')} ${selectedOffice.value.name}` : ''
    }

    const generateEndpointAPI = () => {
      emit('clicked-filter', {
        generatedUrl: generatedUrl(),
        periodText: updatePeriodText(),
        officeText: updateOfficeText()
      })
    }

    onMounted(async () => {
      getTags()
      getOffice()
      getRegions()
      handlePeriod()
      updatePeriodText()
      generateEndpointAPI()
    })

    return {
      form,
      periodReportText,
      LOV,
      dateFormat,
      handlePeriod,
      handleOffice,
      updatePeriodText,
      generateEndpointAPI
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.select-size-md .vs__dropdown-toggle {
  height: 39px;
}
</style>
