<template>
  <div>

    <!-- Filter Section -->
    <FilterReportCH title="Filters" :baseReportUrl="baseReportUrl" @clicked-filter="getReport" />

    <div class="mt-1 mb-1 d-flex justify-content-end">

      <b-button variant="outline-primary" @click="print">
        <feather-icon icon="FileIcon" />
        {{ $t('globalActions.print') }}
      </b-button>

      <b-button-group class="ml-1">
        <b-dropdown right variant="outline-primary">

          <template #button-content>
            <feather-icon icon="DownloadIcon"/>
            {{ $t('globalActions.export') }}
          </template>

          <b-dropdown-item-button @click="exportToPDF">PDF</b-dropdown-item-button>
          <b-dropdown-item-button @click="exportToExcel">Excel</b-dropdown-item-button>

        </b-dropdown>
      </b-button-group>

    </div>

    <!-- Table Container Card -->
    <b-card no-body id="pdfDom">

        <b-card-body class="text-center">
          <h2>{{ companyName }}</h2>
          <h3>CH BACCC</h3>
          <h4 v-if="officeReportText" class="mt-sm-1">{{ officeReportText }}</h4>
          <h4 class="mt-sm-1">{{ periodReportText }}</h4>
        </b-card-body>

        <b-table-simple id="trial-balance-table" ticky-header="500px" responsive>
          <b-thead>
            <b-tr>
              <!-- <b-th v-for="column in tableColumns" :key="column.key" :class="column.thClass" >{{ column.label }}</b-th> -->
              <!-- <b-th rowspan="2">No</b-th> -->
              <b-th rowspan="2" class="text-center align-middle">tag</b-th>
              <b-th rowspan="2" class="text-center align-middle">Date</b-th>
              <b-th rowspan="2" class="text-center align-middle">Description</b-th>
              <b-th rowspan="2" class="text-center align-middle">Invoice</b-th>
              <b-th colspan="3" class="text-center">Debit</b-th>
              <b-th colspan="2" class="text-center">Credit</b-th>
              <b-th rowspan="2" class="text-center align-middle">Balance</b-th>
              <b-th rowspan="2" class="text-center align-middle">Total Balance</b-th>
            </b-tr>
            <b-tr>
              <b-th class="text-right">Amount</b-th>
              <b-th class="text-right">Corection</b-th>
              <b-th class="text-right">Total Amount</b-th>
              <b-th class="text-right">Bank</b-th>
              <b-th class="text-right">Amount</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(prop, index) in itemLists" :key="prop.coa">
              <b-td v-for="column in tableColumns" :key="`${column.key}-${index}`" :class="column.tdClass">{{ prop[column.key] }}</b-td>
            </b-tr>
          </b-tbody>
          <b-tfoot>
            <b-tr>
              <b-td class="text-right font-weight-bolder font-italic">Total</b-td>
              <b-td class="text-right font-weight-bolder font-italic"></b-td>
              <b-td class="text-right font-weight-bolder font-italic"></b-td>
              <b-td class="text-right font-weight-bolder font-italic"></b-td>
              <b-td class="text-right font-weight-bolder font-italic">{{ total.totalBeginningDebit }}</b-td>
              <b-td class="text-right font-weight-bolder font-italic">{{ total.totalBeginningCredit }}</b-td>
              <b-td class="text-right font-weight-bolder font-italic">{{ total.totalMutationDebit }}</b-td>
              <b-td class="text-right font-weight-bolder font-italic"></b-td>
              <b-td class="text-right font-weight-bolder font-italic">{{ total.totalMutationCredit }}</b-td>
              <b-td class="text-right font-weight-bolder font-italic">{{ total.totalBalanceDebit }}</b-td>
              <b-td class="text-right font-weight-bolder font-italic">{{ total.totalBalanceCredit }}</b-td>
              <!-- <b-td class="text-right font-weight-bolder font-italic">{{ total.balance }}</b-td> -->
            </b-tr>
          </b-tfoot>
        </b-table-simple>

    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BButtonGroup,
  BButton,
  BDropdown,
  BDropdownItemButton,
  BTableSimple,
  BThead,
  BTh,
  BTr,
  BTd,
  BTbody,
  BTfoot
} from 'bootstrap-vue'
import FilterReportCH from '@/components/reports/FilterRepotCH.vue'

import { computed } from '@vue/composition-api'
import { formatCurrency } from '@/utils/formatter'
import useListTable from '@/comp-functions/useListTable'
import useFilterReport from '@/comp-functions/useFilterReport'

export default {
  components: {
    BCard,
    BCardBody,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItemButton,
    // BTable,
    BTableSimple,
    BThead,
    BTh,
    BTr,
    BTd,
    BTbody,
    BTfoot,
    FilterReportCH
  },
  computed: {
    companyName () {
      return this.$store.state.user.currentCompany.name
    }
  },
  setup () {
    // export format
    const formats = ['xlsx', 'csv', 'txt']

    // Table Handlers
    const tableColumns = [
      { key: 'tags', label: 'Tags', thClass:'', tdClass: '' },
      { key: 'date', label: 'Dae', thClass:'', tdClass: '' },
      { key: 'description', label: 'Description', thClass:'', tdClass: '' },
      { key: 'code', label: 'Invoice', thClass:'', tdClass: '' },
      { key: 'amount', label: 'Amount', thClass:'text-right', tdClass: 'text-right' },
      { key: 'corection', label: 'Corection', thClass:'text-right', tdClass: 'text-right' },
      { key: 'total_amount', label: 'Total Amount', thClass:'text-right', tdClass: 'text-right' },
      { key: 'bank', label: 'Bank', thClass:'', tdClass: '' },
      { key: 'amount_2', label: 'Amount', thClass:'text-right', tdClass: 'text-right' },
      { key: 'balance', label: 'Balance', thClass:'text-right', tdClass: 'text-right' },
      { key: 'total_balance', label: 'Total Balance', thClass:'text-right', tdClass: 'text-right' },
      // { key: 'beginning_balance_debit_view', label: 'Debit', thClass:'text-right', tdClass: 'text-right' },
      // { key: 'beginning_balance_credit_view', label: 'Credit', thClass:'text-right', tdClass: 'text-right' },
      // { key: 'mutation_balance_debit_view', label: 'Debit', thClass:'text-right', tdClass: 'text-right' },
      // { key: 'mutation_balance_credit_view', label: 'Credit', thClass:'text-right', tdClass: 'text-right' },
      // { key: 'ending_balance_debit_view', label: 'Debit', thClass:'text-right', tdClass: 'text-right' },
      // { key: 'ending_balance_credit_view', label: 'Credit', thClass:'text-right', tdClass: 'text-right' },
      // { key: 'balance_view', label: 'Balance', thClass:'text-right', tdClass: 'text-right' }
    ]

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    } = useListTable({ url: false })

    const total = computed(() => {
      const totalBeginningDebit = formatCurrency(itemLists.value.reduce((accumulator, {amount}) => accumulator + amount, 0))
      const totalBeginningCredit = formatCurrency(itemLists.value.reduce((accumulator, {corection}) => accumulator + corection, 0))
      const totalMutationDebit = formatCurrency(itemLists.value.reduce((accumulator, {total_amount}) => accumulator + total_amount, 0))
      const totalMutationCredit = formatCurrency(itemLists.value.reduce((accumulator, {amount_2}) => accumulator + amount_2, 0))
      const totalBalanceDebit = formatCurrency(itemLists.value.reduce((accumulator, {balance}) => accumulator + balance, 0))
      const totalBalanceCredit = formatCurrency(itemLists.value.reduce((accumulator, {total_balance}) => accumulator + total_balance, 0))
      return { totalBeginningDebit, totalBeginningCredit ,totalMutationDebit, totalMutationCredit ,totalBalanceDebit, totalBalanceCredit }
    })

    return {
      total,
      formatCurrency,
      formats,
      tableColumns,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      ...useFilterReport({
        baseUrl: 'reports/chbacc',
        fetchLists
      })
    }
  },
  methods: {
    print () {
      window.print()
    },
    exportToPDF () {
      this.$nextTick(() => this.getPdf())
    },
    exportToExcel () {
      import('@/vendors/Export2Excel').then(excel => {
        const list = this.itemLists

        // add title
        // const title = [this.$store.state.user.currentCompany.name, this.$t('apps.reportLedger.singular.ledgerReport'), this.periodReportText]
        const headerTitle = this.tableColumns.map(column => column.label)
        // only take indexData from columnDef,
        const headerVal = this.tableColumns.map(column => column.key)
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          multiHeader: [
            [this.$store.state.user.currentCompany.name, '', '', '', '', '', ''],
            ['Ledger Report', '', '', '', '', '', ''],
            [this.periodReportText, '', '', '', '', '', ''],
            ['', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 6, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 6, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 6, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 6, r: 3 } }
          ],
          filename: `Ledger Report ${this.periodReportText}`,
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .dropdown-menu .dropdown-item {
    width: 100%;
  }

  @media print {
    #app {
      * {
        visibility: hidden;
        margin: 0 !important;
      }

      #pdfDom,
      #pdfDom * {
        visibility: visible;
      }

      #pdfDom {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        margin-top: 100px;
      }
    }
  }

  @page {
    size: auto;
  }
</style>
